import { COMPANY_ITEM_MAP } from '@/const/codes.ts'

// 欄位說明：
// id: checkbox選項id
// showKey: 判斷顯示的區塊的索引
// searchKey: api request使用的參數("get"欄位值)

export default [
  {
    id: '基本資料',
    label: '基本資料',
    children: [
      {
        id: '公司簡介',
        label: COMPANY_ITEM_MAP.description.name,
        showKey: 'description',
        searchKey: COMPANY_ITEM_MAP.description.searchKey
      },
      {
        id: '商工登記資料',
        label: COMPANY_ITEM_MAP.basic.name,
        showKey: 'basic',
        searchKey: COMPANY_ITEM_MAP.basic.searchKey
      },
      {
        id: '董監事資料',
        label: COMPANY_ITEM_MAP.shareholders.name,
        showKey: 'shareholders',
        searchKey: COMPANY_ITEM_MAP.shareholders.searchKey
      },
      {
        id: '經理人資料',
        label: COMPANY_ITEM_MAP.managers.name,
        showKey: 'managers',
        searchKey: COMPANY_ITEM_MAP.managers.searchKey
      },
      {
        id: '變更紀錄',
        label: COMPANY_ITEM_MAP.companys_history.name,
        showKey: 'companys_history',
        searchKey: COMPANY_ITEM_MAP.companys_history.searchKey
      },
      {
        id: '分公司資料',
        label: COMPANY_ITEM_MAP.company_branchs.name,
        showKey: 'company_branchs',
        searchKey: COMPANY_ITEM_MAP.company_branchs.searchKey
      },
      {
        id: '工廠登記',
        label: COMPANY_ITEM_MAP.factorys.name,
        showKey: 'factorys',
        searchKey: COMPANY_ITEM_MAP.factorys.searchKey
      },
      {
        id: '登記地址近似公司',
        label: COMPANY_ITEM_MAP.登記地址近似公司.name,
        showKey: '登記地址近似公司',
        searchKey: COMPANY_ITEM_MAP.登記地址近似公司.searchKey
      }
    ]
  },
  {
    id: '新聞事件',
    label: COMPANY_ITEM_MAP.news.name,
    showKey: 'news',
    searchKey: COMPANY_ITEM_MAP.news.searchKey
  },
  {
    id: '法律訴訟',
    label: COMPANY_ITEM_MAP.verdicts.name,
    showKey: 'verdicts',
    searchKey: COMPANY_ITEM_MAP.verdicts.searchKey
  },
  {
    id: '關係人地圖',
    label: '關係人地圖',
    children: [
      {
        id: '股權結構分析圖',
        label: COMPANY_ITEM_MAP.stock_relationship_chart.name,
        showKey: 'stock_relationship_chart',
        searchKey: COMPANY_ITEM_MAP.stock_relationship_chart.searchKey
      },
      {
        id: '企業關聯圖譜',
        label: COMPANY_ITEM_MAP.stock_directed_chart.name,
        showKey: 'stock_directed_chart',
        searchKey: COMPANY_ITEM_MAP.stock_directed_chart.searchKey
      }
    ]
  },
  {
    id: '風險指標',
    label: '風險指標',
    children: [
      {
        id: '違反勞動法令案件',
        label: COMPANY_ITEM_MAP.disputes.name,
        showKey: 'disputes',
        searchKey: COMPANY_ITEM_MAP.disputes.searchKey
      },
      {
        id: '拒絕往來廠商紀錄',
        label: COMPANY_ITEM_MAP.procurement_bad_vendors.name,
        showKey: 'procurement_bad_vendors',
        searchKey: COMPANY_ITEM_MAP.procurement_bad_vendors.searchKey
      }
    ]
  },
  {
    id: '經營指標',
    label: '經營指標',
    children: [
      {
        id: '進出口實續',
        label: COMPANY_ITEM_MAP.trades.name,
        showKey: 'trades',
        searchKey: COMPANY_ITEM_MAP.trades.searchKey
      },
      {
        id: '政府標案',
        label: COMPANY_ITEM_MAP.procurements.name,
        showKey: 'procurements',
        searchKey: COMPANY_ITEM_MAP.procurements.searchKey
      },
      {
        id: '人力招聘',
        label: COMPANY_ITEM_MAP.jobs.name,
        showKey: 'jobs',
        searchKey: COMPANY_ITEM_MAP.jobs.searchKey
      },
      {
        id: '專利資訊',
        label: COMPANY_ITEM_MAP.patents.name,
        showKey: 'patents',
        searchKey: COMPANY_ITEM_MAP.patents.searchKey
      },
      {
        id: '得獎紀錄與政府推薦名單',
        label: COMPANY_ITEM_MAP.awards.name,
        showKey: 'awards',
        searchKey: COMPANY_ITEM_MAP.awards.searchKey
      }
    ]
  },
  {
    id: '財務指標',
    label: '財務指標',
    children: [
      {
        id: '公司近期發布之重大訊息',
        label: COMPANY_ITEM_MAP.publics_news.name,
        showKey: 'publics_news',
        searchKey: COMPANY_ITEM_MAP.publics_news.searchKey
      },
      {
        id: '營收資訊',
        label: COMPANY_ITEM_MAP.revenue_history.name,
        showKey: 'revenue_history',
        searchKey: COMPANY_ITEM_MAP.revenue_history.searchKey
      },
      {
        id: '採IFRSs後財報資訊',
        label: COMPANY_ITEM_MAP.ifrss_summary.name,
        showKey: 'ifrss_summary',
        searchKey: COMPANY_ITEM_MAP.ifrss_summary.searchKey
      },
    ]
  }
]
