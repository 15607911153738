<template>
  <main class="page-home">
    <div id="printContainer" class="container">
      <el-tabs v-model="indexTabs">
        <el-tab-pane label="單一搜尋" name="search">
          <TheCompanySearchTab>
          </TheCompanySearchTab>
        </el-tab-pane>
        <el-tab-pane label="比較人物" name="second" class="el-tab-pane--custom">
          <TheMultiPersonSearchTab>
          </TheMultiPersonSearchTab>
        </el-tab-pane>
        <el-tab-pane label="比較公司" name="third">
          <TheMultiCompanySearchTab>
          </TheMultiCompanySearchTab>
        </el-tab-pane>
      </el-tabs>
    </div>
    <MainPageFloatButton>
    </MainPageFloatButton>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import TheCompanySearchTab from '@/components/TheCompanySearchTab/TheCompanySearchTab.vue'
import TheMultiPersonSearchTab from '@/components/TheMultiPersonSearchTab/TheMultiPersonSearchTab.vue'
import TheMultiCompanySearchTab from '@/components/TheMultiCompanySearchTab/TheMultiCompanySearchTab.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'

export default {
  name: 'home',
  components: {
    TheCompanySearchTab,
    TheMultiPersonSearchTab,
    TheMultiCompanySearchTab,
    MainPageFloatButton
  },
  data () {
    return {
      indexTabs: 'search'
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('home', [
      'actionOptions'
    ])
  },
  mounted () {
    // 將網址修改為 '/'
    window.history.replaceState(window.history.state, '', '/')
  },
  created () {
    this.actionOptions()
  }
}
</script>
