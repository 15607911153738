<template>
  <el-dialog
    title="問題回報"
    :visible.sync="currentVisible">
    <section>
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        @submit.native.prevent>
        <el-form-item prop="type" label="問題類型">
          <el-radio-group v-model="form.type">
            <el-radio label="資料缺漏"></el-radio>
            <el-radio label="登入異常"></el-radio>
            <el-radio label="網頁顯示異常"></el-radio>
            <el-radio label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="description" label="問題描述">
          <el-input
            type="textarea"
            maxlength="500"
            show-word-limit
            placeholder="您所填寫的問題描述越詳細，越能讓我們盡快幫您解決問題唷 :)"
            v-model="form.description">
          </el-input>
        </el-form-item>
        <el-form-item
          label="圖片上傳">
          <!-- <span scoped-slot="label">
            <div class="form-label">圖片上傳</div>
          </span>
          等會切 -->
          <el-upload
            drag
            style="width:360px"
            :action="''"
            :accept="acceptFileTypes"
            :show-file-list="false"
            :before-upload="beforeUpload"
            v-loading="isImageLoading">
            <img
              style="width:360px"
              :src="form.image"
              v-if="form.image">
            <i
              class="el-icon-upload avatar-uploader-icon"
              v-else></i>
            <div class="el-upload__text">請將檔案拖曳至此處 或 <em>點擊上傳</em></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="頁面連結">
          <el-input
            type="text"
            v-model="form.page_url">
          </el-input>
        </el-form-item>
      </el-form>
    </section>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="default"
        @click="closeLightbox">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="isSubmitLoading"
        @click="submitForm">
        送出
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as apis from '@/apis/index.ts'
import * as screenImageGetter from '@/utils/screenImageGetter.ts'
// import html2canvas from 'html2canvas'
// import canvg from 'canvg'
// import jsPDF from 'jspdf'

// // 截取畫面轉為base64
// async function getScreenImageBase64 (selector) {
//   try {
//     // -- svg轉canvas --
//     let nodesToRecover = []
//     let nodesToRemove = []
//     let svgElem = document.querySelectorAll(selector + ' svg')
//     if (svgElem && svgElem.length) {
//       for (let i = 0; i++; i < i.length) {
//         let node = svgElem[i]
//         let parentNode = node.parentNode
//         let svg = node.outerHTML.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') // 等同.trim()

//         let canvas = document.createElement('canvas')
//         canvas.width = 650
//         canvas.height = 480
//         if (node.style.position) {
//           canvas.style.position += node.style.position
//           canvas.style.left += node.style.left
//           canvas.style.top += node.style.top
//         }
//         if (node.clientWidth) {
//           canvas.width = node.clientWidth
//         }
//         if (node.clientHeight) {
//           canvas.height = node.clientHeight
//         }
//         if (node.className) {
//           canvas.className = node.className
//         }
//         canvg(canvas, svg)

//         nodesToRecover.push({
//           parent: parentNode,
//           child: node
//         })
//         parentNode.removeChild(node)

//         nodesToRemove.push({
//           parent: parentNode,
//           child: canvas
//         })

//         parentNode.appendChild(canvas)
//       }
//     }

//     const canvas = await html2canvas(document.querySelector(selector), {
//       background: '#fff'
//     })

//     // -- svg還原 --
//     if (nodesToRemove.length && nodesToRecover.length) {
//       nodesToRemove.forEach(d => {
//         d.parent.removeChild(d.child)
//       })
//       nodesToRecover.forEach(d => {
//         d.parent.appendChild(d.child)
//       })
//     }

//     // -- 回傳base64圖片 --
//     if (canvas) {
//       return canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
//     } else {
//       return null
//     }

//     // -- 如果是要下載的話使用以下這段 --
//     // let img = document.createElement('a')
//     // img.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
//     // img.download = 'case.jpg'
//     // img.click()

//     // -- 如果是要下載pdf的話使用以下這段 --
//     // var contentWidth = canvas.width;
//     // var contentHeight = canvas.height;
//     // var padding = 20; // 邊距

//     // //一页pdf显示html页面生成的canvas高度;
//     // var pageHeight = contentWidth / 592.28 * 841.89;
//     // //未生成pdf的html页面高度
//     // var leftHeight = contentHeight;
//     // //页面偏移
//     // var position = 0;
//     // //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
//     // var imgWidth = 595.28 - (padding * 2);
//     // var imgHeight = (592.28 - (padding * 2))/contentWidth * contentHeight;

//     // var pageData = canvas.toDataURL('image/jpeg', 1.0);

//     // var pdf = new jsPDF('', 'pt', 'a4');

//     // //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
//     // //当内容未超过pdf一页显示的范围，无需分页
//     // if (leftHeight < pageHeight) {
//     // pdf.addImage(pageData, 'JPEG', padding, 0, imgWidth, imgHeight );
//     // } else {
//     //     while(leftHeight > 0) {
//     //         pdf.addImage(pageData, 'JPEG', padding, position, imgWidth, imgHeight)
//     //         leftHeight -= pageHeight;
//     //         position -= 841.89;
//     //         //避免添加空白页
//     //         if(leftHeight > 0) {
//     //           pdf.addPage();
//     //         }
//     //     }
//     // }

//     // pdf.save('content.pdf');

    
//   } catch (e) {
//     // console.log(e)
//     return null
//   }
// }

export default {
  props: {
    'visible': {
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isImageLoading: true,
      isSubmitLoading: false,
      acceptFileTypes: '.jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF',
      form: {
        type: '',
        description: '',
        image: '',
        page_url: window.location.href
      },
      rules: {
        type: [
          { required: true, message: '必填', trigger: 'change' },
        ],
        description: [
          { required: true, message: '必填', trigger: 'change' },
        ]
      }
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (d) {
        this.$emit('update:visible', d)
      }
    }
  },
  methods: {
    closeLightbox () {
      this.currentVisible = false
    },
    beforeUpload (file) {
      const isCheck = file.type === 'image/png' || 'image/jpg' || 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 10

      if (!isCheck) {
        this.$message.error('圖片只能是 jpg, png, jepg 格式!')
      }
      if (!isLt2M) {
        this.$message.error('檔案大小不可超過 10MB!')
      }
      if (isCheck && isLt2M) {
        this.$set(this.form, 'image', URL.createObjectURL(file))
        // this.$set(this.form, 'imageFile', file)
      } else {
        this.$set(this.form, 'image', '')
        // this.$set(this.form, 'imageFile', null)
      }
    },
    submitForm () {
      this.$refs.form.validate(async (valid) => {
        if (valid == true) {
          // let sendFormData = new FormData()
          // sendFormData.append('type', this.form.type)
          // sendFormData.append('description', this.form.description)
          // sendFormData.append('page_url', this.form.page_url)
          // sendFormData.append('image', this.form.image)

          this.isSubmitLoading = true
          let result = await apis.reportAdd(this.form)
          this.isSubmitLoading = false
          if (result.success == true) {
            this.closeLightbox()
          }
        }
      })
    }
  },
  watch: {
    'currentVisible': {
      async handler (d) {
        if (d === true) {
          this.isImageLoading = true
          this.$set(this.form, 'image', '')
          setTimeout(async () => {
            const imageBase64 = await screenImageGetter.getScreenBase64('#printContainer')
            if (imageBase64) {
              this.$set(this.form, 'image', imageBase64)
            }
            this.isImageLoading = false
          }, 0)
        }
      }
    }
  }
}
</script>
