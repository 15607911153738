export default [
  {
    label: '商工登記',
    value: 'companys'
  },
  {
    label: '法律訴訟',
    value: 'verdicts'
  },
  {
    label: '新聞事件',
    value: 'news' // @Q@ api還沒開出來先自己隨便取個名字
  }
]
