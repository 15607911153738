export default [
  {
    label: '01 農、牧業',
    value: '01'
  },
  {
    label: '02 林業',
    value: '02'
  },
  {
    label: '03 漁業',
    value: '03'
  },
  {
    label: '05 石油及天然氣礦業',
    value: '05'
  },
  {
    label: '06 砂、石採取及其他礦業',
    value: '06'
  },
  {
    label: '08 食品及飼品製造業',
    value: '08'
  },
  {
    label: '09 飲料製造業',
    value: '09'
  },
  {
    label: '10 菸草製造業',
    value: '10'
  },
  {
    label: '11 紡織業',
    value: '11'
  },
  {
    label: '12 成衣及服飾品製造業',
    value: '12'
  },
  {
    label: '13 皮革、毛皮及其製品製造業',
    value: '13'
  },
  {
    label: '14 木竹製品製造業',
    value: '14'
  },
  {
    label: '15 紙漿、紙及紙製品製造業',
    value: '15'
  },
  {
    label: '16 印刷及資料儲存媒體複製業',
    value: '16'
  },
  {
    label: '17 石油及煤製品製造業',
    value: '17'
  },
  {
    label: '18 化學原材料、肥料、氮化合物、塑橡膠原料及人造纖維製造業',
    value: '18'
  },
  {
    label: '19 其他化學製品製造業',
    value: '19'
  },
  {
    label: '20 藥品及醫用化學製品製造業',
    value: '20'
  },
  {
    label: '21 橡膠製品製造業',
    value: '21'
  },
  {
    label: '22 塑膠製品製造業',
    value: '22'
  },
  {
    label: '23 非金屬礦物製品製造業',
    value: '23'
  },
  {
    label: '24 基本金屬製造業',
    value: '24'
  },
  {
    label: '25 金屬製品製造業',
    value: '25'
  },
  {
    label: '26 電子零組件製造業',
    value: '26'
  },
  {
    label: '27 電腦、電子產品及光學製品製造業',
    value: '27'
  },
  {
    label: '28 電力設備及配備製造業',
    value: '28'
  },
  {
    label: '29 機械設備製造業',
    value: '29'
  },
  {
    label: '30 汽車及其零件製造業',
    value: '30'
  },
  {
    label: '31 其他運輸工具及其零件製造業',
    value: '31'
  },
  {
    label: '32 家具製造業',
    value: '32'
  },
  {
    label: '33 其他製造業',
    value: '33'
  },
  {
    label: '34 產業用機械設備維修及安裝業',
    value: '34'
  },
  {
    label: '35 電力及燃氣供應業',
    value: '35'
  },
  {
    label: '36 用水供應業',
    value: '36'
  },
  {
    label: '37 廢水及污水處理業',
    value: '37'
  },
  {
    label: '38 廢棄物清除、處理及資源回收處理業',
    value: '38'
  },
  {
    label: '39 污染整治業',
    value: '39'
  },
  {
    label: '41 污染整治業',
    value: '41'
  },
  {
    label: '42 土木工程業',
    value: '42'
  },
  {
    label: '43 專門營造業',
    value: '43'
  },
  {
    label: '45-46 批發業',
    value: '45,46'
  },
  {
    label: '47-48 零售業',
    value: '47,48'
  },
  {
    label: '49 陸上運輸業',
    value: '49'
  },
  {
    label: '50 水上運輸業',
    value: '50'
  },
  {
    label: '51 航空運輸業',
    value: '51'
  },
  {
    label: '52 運輸輔助業',
    value: '52'
  },
  {
    label: '53 倉儲業',
    value: '53'
  },
  {
    label: '54 郵政及快遞業',
    value: '54'
  },
  {
    label: '55 住宿業',
    value: '55'
  },
  {
    label: '56 餐飲業',
    value: '56'
  },
  {
    label: '58 出版業',
    value: '58'
  },
  {
    label: '59 影片及電視節目業；聲音錄製及音樂發行業',
    value: '59'
  },
  {
    label: '60 廣播、電視節目編排及傳播業',
    value: '60'
  },
  {
    label: '61 電信業',
    value: '61'
  },
  {
    label: '62 電腦程式設計、諮詢及相關服務業',
    value: '62'
  },
  {
    label: '63 資訊服務業',
    value: '63'
  },
  {
    label: '64 金融服務業',
    value: '64'
  },
  {
    label: '65 保險業',
    value: '65'
  },
  {
    label: '66 證券期貨及金融輔助業',
    value: '66'
  },
  {
    label: '67 不動產開發業',
    value: '67'
  },
  {
    label: '68 不動產經營及相關服務業',
    value: '68'
  },
  {
    label: '69 法律及會計服務業',
    value: '69'
  },
  {
    label: '70 企業總管理機構及管理顧問業',
    value: '70'
  },
  {
    label: '71 建築、工程服務及技術檢測、分析服務業',
    value: '71'
  },
  {
    label: '72 研究發展服務業',
    value: '72'
  },
  {
    label: '73 廣告業及市場研究業',
    value: '73'
  },
  {
    label: '74 專門設計業',
    value: '74'
  },
  {
    label: '75 獸醫業',
    value: '75'
  },
  {
    label: '76 其他專業、科學及技術服務業',
    value: '76'
  },
  {
    label: '77 租賃業',
    value: '77'
  },
  {
    label: '78 人力仲介及供應業',
    value: '78'
  },
  {
    label: '79 旅行及相關服務業',
    value: '79'
  },
  {
    label: '80 保全及偵探業',
    value: '80'
  },
  {
    label: '81 建築物及綠化服務業',
    value: '81'
  },
  {
    label: '82 行政支援服務業',
    value: '82'
  },
  {
    label: '83 公共行政及國防；強制性社會安全',
    value: '83'
  },
  {
    label: '84 國際組織及外國機構',
    value: '84'
  },
  {
    label: '85 教育業',
    value: '85'
  },
  {
    label: '86 醫療保健業',
    value: '86'
  },
  {
    label: '87 居住型照顧服務業',
    value: '87'
  },
  {
    label: '88 其他社會工作服務業',
    value: '88'
  },
  {
    label: '90 創作及藝術表演業',
    value: '90'
  },
  {
    label: '91 圖書館、檔案保存、博物館及類似機構',
    value: '91'
  },
  {
    label: '92 博弈業',
    value: '92'
  },
  {
    label: '93 運動、娛樂及休閒服務業',
    value: '93'
  },
  {
    label: '94 宗教、職業及類似組織',
    value: '94'
  },
  {
    label: '95 個人及家庭用品維修業',
    value: '95'
  },
  {
    label: '96 未分類其他服務業',
    value: '96'
  },
]
