export default [
  {
    label: '公司',
    value: 'company'
  },
  {
    label: '商業登記',
    value: 'registraction'
  },
  {
    label: '統一編號',
    value: 'tax_number'
  }
]
