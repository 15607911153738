export default [
  {
    label: '負責人姓名',
    value: 'representative_name'
  },
  {
    label: '董監事姓名',
    value: 'shareholders.name'
  },
  {
    label: '其他關係人',
    value: 'man_related'
  }
]
