<template>
  <el-form
    ref="form"
    label-position="top"
    :form="searchData"
    :model="searchData"
    @submit.native.prevent>
    <section>
      <div class="index-title">比較公司</div>
      <el-form-item
        :prop="'list[' + index + ']'"
        :rules="[{ validator: validateIsAvailableCompany, trigger: 'change' },{ required: true, message: '必填', trigger: 'change' }]"
        :key="index"
        v-for="(item,index) in searchData.list">
        <!-- <el-input
          placeholder="請輸入關鍵字"
          class="index-input-with-select"
          v-model="item.keyword">
          <el-select v-model="item.keyword_type" slot="prepend" placeholder="請選擇">
            <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in companyKeywordTypeOptions">
            </el-option>
          </el-select>
        </el-input> -->
        <el-autocomplete
          placeholder="請輸入關鍵字"
          class="index-input-with-select"
          :trigger-on-focus="false"
          :fetch-suggestions="autocomplete"
          :debounce="300"
          v-model="item.keyword"
          @select="onAutocompleteSelect"
          @blur="onAutocompleteBlur(index)"
          @focus="onAutocompleteFocus(index)">
          <el-select
            v-model="item.keyword_type"
            slot="prepend"
            placeholder="請選擇"
            style="width:120px"
            @change="onKeywordTypeChange(index)">
            <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in compareCompanyTypeOptions">
            </el-option>
          </el-select>
          <template slot-scope="{ item }">
            <div style="padding-top:7px;padding-bottom:7px">
              <div style="line-height: normal;">
                {{item.value}}
              </div>
              <div style="line-height: normal;font-size: 12px;color: #b4b4b4;">
                {{item.subtitle}}
              </div>
            </div>
          </template>
        </el-autocomplete>
        <!-- 如果只有一個input，不顯示delete -->
        <el-button
          class="delete"
          type="text"
          v-if="searchData.list.length > 1"
          @click="removeKeywordInput(index)">
          <font-awesome-icon :icon="[ 'fas', 'times' ]" />
        </el-button>
      </el-form-item>
      <el-form-item class="el-form-item-btns">
        <el-button
          type="primary"
          size="mini"
          @click="addKeywordInput">
          <font-awesome-icon :icon="[ 'fas', 'plus' ]" />
        </el-button>
      </el-form-item>
    </section>
    <section>
      <div class="index-title">比較項目</div>
      <el-form-item
        prop="compareItems"
        :rules="[{ required: true, message: '至少選取一個比較項目', trigger: 'change' }]">
        <el-tree
          ref="compareCompanyItem"
          :data="compareCompanyItemOptions"
          show-checkbox
          node-key="id"
          empty-text="目前無資料"
          :props="defaultProps"
          :default-checked-keys="['基本資料']"
          @check-change="onCompareCompanyItemChange">
        </el-tree>
      </el-form-item>
    </section>
    <div class="btn-group btn-group--fill">
      <el-button
        type="primary"
        :loading="isLoading"
        @click="search">開始比較</el-button>
      <el-button
        type="text"
        @click="reset">重設條件</el-button>
    </div>
  </el-form>
</template>

<script>
import routerService from '@/utils/routerService.js'
import { mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
// import companyKeywordTypeOptions from '@/const/companyKeywordTypeOptions.js'
import compareCompanyTypeOptions from '@/const/compareCompanyTypeOptions.js'
import compareCompanyItemOptions from '@/const/compareCompanyItemOptions.js'

const defaultSearchData = {
  list: [
    {
      uniID: '',
      keyword: '', // 輸入框的值
      keyword_type: 'company' // 選單的值
    }
  ],
  compareItems: []
}

export default {
  data () {
    return {
      // companyKeywordTypeOptions,
      compareCompanyTypeOptions,
      // compareCompanyItemOptions,
      isLoading: false,
      searchData: JSON.parse(JSON.stringify(defaultSearchData)),
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      focusedIndex: -1,
      autocompleteArr: [[]]
    }
  },
  computed: {
    ...mapGetters([
      'getterLimitD',
      'getterLimitE',
      'getterLimitF',
      'getterLimitG',
    ]),
    compareCompanyItemOptions () {
      let options = JSON.parse(JSON.stringify(compareCompanyItemOptions))
      if (this.getterLimitD === false) {
        options[0].disabled = true
      }
      if (this.getterLimitE === false) {
        options[1].disabled = true
      }
      if (this.getterLimitF === false) {
        options[2].disabled = true
      }
      if (this.getterLimitG === false) {
        options[3].disabled = true
      }
      return options
    },
    currentKeywordType () {
      return this.searchData.list[this.focusedIndex].keyword_type
    }
  },
  methods: {
    onKeywordTypeChange (index) {
      this.$set(this.searchData.list[index], 'keyword', '')
      this.$set(this.autocompleteArr, index, [])
    },
    onCompareCompanyItemChange () {
      const compareCompanyItemNodes = this.$refs.compareCompanyItem.getCheckedNodes()
      let compareItems = []
      compareCompanyItemNodes.forEach(d => {
        if (d.searchKey) {
          compareItems.push(d)
        }
      })
      this.$set(this.searchData, 'compareItems', compareItems)
      // if (this.searchData.compareItems.length === 0) {
      //   this.$message.error('至少選擇一個比較項目')
      //   return
      // }
    },
    validateIsAvailableCompany (rule, value, callback) {
      // @Q@ 星展特規
      let keyword = value.keyword
      if (keyword) {
        keyword = keyword.replace('-台灣分公司', '').replace('台灣分公司', '').replace('-台灣辦事處', '').replace('台灣辦事處', '')
      }

      const leftBracketsIndex = rule.field.indexOf('[')
      const rightBracketsIndex = rule.field.indexOf(']')
      const index = rule.field.slice(leftBracketsIndex + 1, rightBracketsIndex)
      if (!this.autocompleteArr[index] || !this.autocompleteArr[index].length) {
        callback(new Error('沒有符合的公司'))
      } else if (this.autocompleteArr[index].find(d => d.value === keyword) == null) {
        callback(new Error('沒有符合的公司'))
      } else {
        callback()
      }
    },
    onAutocompleteSelect (item) {
      this.$set(this.searchData.list[this.focusedIndex], 'uniID', item.uniID)
    },
    onAutocompleteBlur (index) {
      if (this.autocompleteArr[index].length) {
        const data = this.autocompleteArr[index][0] // 預設取得第0筆資料
        this.$set(this.searchData.list[index], 'uniID', data.uniID)
      }
    },
    onAutocompleteFocus (index) {
      this.focusedIndex = index
    },
    autocomplete (queryString, cb) {
      // @Q@ 星展特規
      queryString = queryString.replace('-台灣分公司', '').replace('台灣分公司', '').replace('-台灣辦事處', '').replace('台灣辦事處', '')

      let searchRequest = {
        'basic':{
          'get':[
            'list'
          ],
          'keyword': queryString,
          // 'keyword_type': this.searchData.list[this.focusedIndex].keyword_type,//'representative_name',
          'keyword_type': 'all',
          'fetch_less_fields': 1,
          'page-size': 10,
          'page-index': 1
        }
      }
      
      apis.companyListGet(searchRequest).then((data) => {
        if (data && data.payload && data.payload.list) {
          // -- 顯示格式所需資料 --
          let list = []
          if (this.currentKeywordType === 'all' ||
          this.currentKeywordType === 'company' ||
          this.currentKeywordType === 'registraction') {
            list = data.payload.list.map(d => {
              d.value = d.company_name
              d.subtitle = `${d.company_county} | ${d.company_operate}`
              return d
            })
          } else if (this.currentKeywordType === 'owner') {
            list = data.payload.list.map(d => {
              d.value = d.representative_name
              // d.subtitle = `${d.company_name}`
              d.subtitle = ''
              return d
            })
            // 過濾重覆的資料
            list = data.payload.list.reduce((prev, data) => {
              if (prev.find(d => d.value === data.value) == null) {
                prev.push(data)
              }
              return prev
            }, [])
          } else if (this.currentKeywordType === 'tax_number') {
            list = data.payload.list.map(d => {
              d.value = d.uniID
              d.subtitle = `${d.company_name}`
              return d
            })
          }
          // 紀錄至全域
          this.$set(this.autocompleteArr, this.focusedIndex, list)
          // 回傳結果
          cb(list)
          // 驗證
          this.$refs.form.validate()
        }
      })
    },
    addKeywordInput () {
      this.searchData.list.push({
        uniID: '',
        keyword: '', // 輸入框的值
        keyword_type: 'company' // 選單的值
      })
    },
    removeKeywordInput (index) {
      this.searchData.list.splice(index, 1)
    },
    reset () {
      this.searchData = JSON.parse(JSON.stringify(defaultSearchData))
    },
    async search () {
      // -- 檢查是否關鍵字填寫完整 --
      // let checkEmpty = false
      // this.searchData.list.forEach(d => {
      //   if (!d.keyword) {
      //     checkEmpty = true
      //   }
      // })
      // if (checkEmpty) {
      //   this.$message.error('比較公司關鍵字未填寫完整')
      //   return
      // }
      let valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      // -- 比較項目 --
      // const compareCompanyItemNodes = this.$refs.compareCompanyItem.getCheckedNodes()
      // let compareItems = []
      // compareCompanyItemNodes.forEach(d => {
      //   if (d.searchKey) {
      //     compareItems.push(d.searchKey)
      //   }
      // })
      // this.$set(this.searchData, 'compareItems', compareItems)
      // if (this.searchData.compareItems.length === 0) {
      //   this.$message.error('至少選擇一個比較項目')
      //   return
      // }
      // // -- 查詢條件 --
      // let searchRequestList = []
      // this.searchData.list.forEach((d, i) => {
      //   let query = {
      //     basic: {
      //       get: ['list'],
      //       'page-index': 1,
      //       sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE)
      //     }
      //   }
      //   if (d.keyword) {
      //     query.basic.keyword = d.keyword
      //   } else {
      //     query.basic.ignore_keyword = 1
      //   }
      //   if (d.keyword_type) {
      //     query.basic.keyword_type = d.keyword_type
      //   }
      //   searchRequestList.push(query)
      // })
      // // -- 導頁前先取得公司的id --
      // // 每個公司各別查詢放入promise
      // let promiseCompanyList = []
      // searchRequestList.forEach(searchRequest => {
      //   const result = apis.companyListGet(searchRequest)
      //     .then((data) => {
      //       if (data.payload.list && data.payload.list.length) {
      //         return data.payload.list[0]
      //       }
      //     })
      //     .catch(e => {
      //     })
      //   if (result) {
      //     promiseCompanyList.push(result)
      //   }
      // })
      // // 全部查詢完成
      // this.isLoading = true
      // const companyList = await Promise.all(promiseCompanyList)
      // this.isLoading = false
      // let uniIDs = ''
      // if (companyList && companyList.length) {
      //   uniIDs = companyList.map(d => d.uniID)
      // } else {
      //   this.$message.error('公司查詢錯誤')
      //   return
      // }
      const uniIDList = this.searchData.list.map(d => d.uniID)
      let get = {}
      let _show = []
      this.searchData.compareItems.forEach(d => {
        get[d.searchKey] = {}
        _show.push(d.showKey)
      })
      // -- router（到查詢結果頁再依據uniID及相關參數來查詢） --
      // this.$router.push({
      routerService('push', {
        name: 'multiCompanyDetailByCompany',
        params: {
          menuIndex: uniIDList[0] // 預設先顯示第一個公司
        },
        query: {
          uniIDs: uniIDList.join(','),
          get: JSON.stringify(get),
          _show: JSON.stringify(_show)
        }
      })
    }
  },
  mounted () {
    // 初始化companyItems的資料
    this.onCompareCompanyItemChange()
  }
}
</script>
