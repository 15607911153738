export default [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '公司',
    value: 'company'
  },
  {
    label: '商業登記',
    value: 'registraction'
  },
  {
    label: '人名',
    value: 'owner'
  },
  {
    label: '統一編號',
    value: 'tax_number'
  }
]
