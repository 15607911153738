<template>
  <el-form
    ref="form"
    label-position="top"
    :form="searchData"
    :model="searchData"
    @submit.native.prevent>
    <section>
      <div class="index-title">比較人物</div>
      <el-form-item
        :prop="'basicList[' + index + ']'"
        :rules="[{ validator: validateIsAvailablePerson, trigger: 'change' },{ required: true, message: '必填', trigger: 'change' }]"
        :key="index"
        v-for="(item,index) in searchData.basicList">
        <!-- <el-row :gutter="5">
          <el-col :span="6">
            <el-select
              v-model="item.field"
              placeholder="請選擇">
              <el-option
                v-for="fieldItem in personFieldOptions"
                :key="fieldItem.value"
                :label="fieldItem.label"
                :value="fieldItem.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="17">
            <el-select
              filterable 
              placeholder="請輸入關鍵字"
              style="width:100%"
              v-model="item.keyword"
              :filter-method="onInput"
              @focus="onAutocompleteFocus(index)">
              <el-option
                v-for="item in autocompleteArr[index]"
                :key="item.value"
                :label="item.value"
                :value="item.uniID">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="1">
            <el-button
              class="delete"
              type="text"
              v-if="searchData.basicList.length"
              @click="removeKeywordInput(index)">
              <font-awesome-icon :icon="[ 'fas', 'times' ]" />
            </el-button>
          </el-col>
        </el-row> -->
        <!-- <el-input placeholder="請輸入關鍵字" v-model="item.keyword" class="index-input-with-select">
          <el-select v-model="item.field" slot="prepend" placeholder="請選擇">
            <el-option
              v-for="fieldItem in personFieldOptions"
              :key="fieldItem.value"
              :label="fieldItem.label"
              :value="fieldItem.value">
            </el-option>
          </el-select>
        </el-input> -->
        <el-autocomplete
          placeholder="請輸入關鍵字"
          class="index-input-with-select"
          :trigger-on-focus="false"
          :fetch-suggestions="autocomplete"
          :debounce="300"
          v-model="item.keyword"
          @focus="onAutocompleteFocus(index)">
          <el-select
            v-model="item.field"
            slot="prepend"
            placeholder="請選擇"
            style="width:120px">
            <el-option
              v-for="fieldItem in personFieldOptions"
              :key="fieldItem.value"
              :label="fieldItem.label"
              :value="fieldItem.value">
            </el-option>
          </el-select>
          <template slot-scope="{ item }">
            <div style="padding-top:7px;padding-bottom:7px">
              <div style="line-height: normal;">
                {{item.value}}
              </div>
              <div style="line-height: normal;font-size: 12px;color: #b4b4b4;">
                {{item.company_name}}
              </div>
            </div>
          </template>
        </el-autocomplete>
        <!-- 如果只有一個input，不顯示delete -->
        <el-button
          class="delete"
          type="text"
          v-if="searchData.basicList.length > 1"
          @click="removeKeywordInput(index)">
          <font-awesome-icon :icon="[ 'fas', 'times' ]" />
        </el-button>
      </el-form-item>
      <el-form-item
        class="el-form-item-btns"
        v-if="searchData.basicList.length<20">
        <el-button
          type="primary"
          size="mini"
          @click="addKeywordInput">
          <font-awesome-icon :icon="[ 'fas', 'plus' ]" />
        </el-button>
      </el-form-item>
    </section>
    <section>
      <div class="index-title">比較項目</div>
      <el-form-item
        prop="compareItems"
        :rules="[{ required: true, message: '至少選取一個比較項目', trigger: 'change' }]">
        <el-checkbox-group v-model="searchData.compareItems">
          <!-- 商工登記 -->
          <el-checkbox
            :label="comparePersonItemOptions[0].value"
            name="compare-people-item">
            {{comparePersonItemOptions[0].label}}
          </el-checkbox>
          <!-- 法律訴訟 -->
          <el-checkbox
            :label="comparePersonItemOptions[1].value"
            name="compare-people-item">
            {{comparePersonItemOptions[1].label}}
          </el-checkbox>
          <el-row v-show="isShowVerdictsOptions" :gutter="10" class="el-form-item__box">
            <el-col :span="11">
              <el-date-picker
                v-model="searchData.verdictsStartDate"
                type="date"
                placeholder="起始時間"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
            <el-col :span="2" style="color: rgb(96, 98, 102); font-size: 14px; text-align: center; line-height: 40px;">
              至
            </el-col>
            <el-col :span="11">
              <el-date-picker
                v-model="searchData.verdictsEndDate"
                type="date"
                placeholder="結束時間"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
          </el-row>
          <!-- 新聞事件 -->
          <el-checkbox
            :label="comparePersonItemOptions[2].value"
            name="compare-people-item">
            {{comparePersonItemOptions[2].label}}
          </el-checkbox>
          <el-row v-show="isShowNewsOptions" :gutter="10" class="el-form-item__box">
            <el-col :span="11">
              <el-date-picker
                v-model="searchData.newsStartDate"
                type="date"
                placeholder="起始時間"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
            <el-col :span="2" style="color: rgb(96, 98, 102); font-size: 14px; text-align: center; line-height: 40px;">
              至
            </el-col>
            <el-col :span="11">
              <el-date-picker
                v-model="searchData.newsEndDate"
                type="date"
                placeholder="結束時間"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </el-form-item>
    </section>
    <div class="btn-group btn-group--fill">
      <el-button
        type="primary"
        @click="search">
        開始比較
      </el-button>
      <el-button
        type="text"
        @click="reset">
        重設條件
      </el-button>
    </div>
  </el-form>
</template>

<script>
import routerService from '@/utils/routerService.js'
import moment from 'moment'
import { mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import personFieldOptions from '@/const/personFieldOptions.js'
import comparePersonItemOptions from '@/const/comparePersonItemOptions.js'

const today = moment().format('YYYY-MM-DD')

export default {
  data () {
    return {
      defaultSearchData: {
        basicList: [
          {
            keyword: '',
            field: 'representative_name',
            'search-label': {
              user: this.getterUserEmail
            }
          }
        ],
        compareItems: ['companys'],
        verdictsStartDate: '',
        verdictsEndDate: today,
        newsStartDate: '',
        newsEndDate: today
      },
      personFieldOptions,
      // comparePersonItemOptions,
      searchData: {},
      focusedIndex: -1,
      autocompleteArr: [[]]
    }
  },
  computed: {
    ...mapGetters([
      'getterUserEmail',
      'getterLimitD',
      'getterLimitE',
    ]),
    comparePersonItemOptions () {
      let options = JSON.parse(JSON.stringify(comparePersonItemOptions))
      if (this.getterLimitD === false) {
        options[0].disabled = true
      }
      if (this.getterLimitE === false) {
        options[1].disabled = true
        options[2].disabled = true
      }
      return options
    },
    isShowVerdictsOptions () {
      if (this.searchData.compareItems.includes('verdicts')) {
        return true
      } else {
        return false
      }
    },
    isShowNewsOptions () {
      if (this.searchData.compareItems.includes('news')) { // @Q@ api還沒開出來先自己隨便取個名字
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    validateIsAvailablePerson (rule, value, callback) {
      const leftBracketsIndex = rule.field.indexOf('[')
      const rightBracketsIndex = rule.field.indexOf(']')
      const index = rule.field.slice(leftBracketsIndex + 1, rightBracketsIndex)
      if (!this.autocompleteArr[index] || !this.autocompleteArr[index].length) {
        callback(new Error('沒有符合的人名'))
      } else if (this.autocompleteArr[index].find(d => d.value === value.keyword) == null) {
        callback(new Error('沒有符合的人名'))
      } else {
        callback()
      }
    },
    onAutocompleteFocus (index) {
      this.focusedIndex = index
    },
    autocomplete (queryString, cb) {
      if (!queryString) {
        return
      }
      
      // let searchRequest = {
      //   'basic':{
      //     'get':[
      //       'list'
      //     ],
      //     'keyword': queryString,
      //     'keyword_type': this.searchData.basicList[this.focusedIndex].field,//'representative_name',
      //     'fetch_less_fields': 1,
      //     'page-size': 10,
      //     'page-index': 1
      //   }
      // }
      
      // apis.companyListGet(searchRequest).then((data) => {
      //   if (data && data.payload && data.payload.list) {
      //     const list = data.payload.list.map(d => {
      //       d.value = d.representative_name
      //       return d
      //     })
      //     // 過濾重覆的資料
      //     const showList = list.reduce((prev, data) => {
      //       if (prev.find(d => d.value === data.value) == null) {
      //         prev.push(data)
      //       }
      //       return prev
      //     }, [])
      //     // 紀錄至全域
      //     this.autocompleteArr[this.focusedIndex] = showList
      //     // 回傳結果
      //     cb(showList)
      //     // 驗證
      //     this.$refs.form.validate()
      //   }
      // })

      let searchRequest = {
        'basic':{
          'get':[
            'list'
          ],
          'keyword': queryString,
          'field': this.searchData.basicList[this.focusedIndex].field,//'representative_name',
          // 'fetch_less_fields': 1,
          'page-size': 10,
          'page-index': 1,
          'search-label': {
            user: this.getterUserEmail,
            label: queryString
          }
        }
      }

      apis.namesGet(searchRequest).then((data) => {
        if (data && data.payload && data.payload.list) {
          let list = data.payload.list.slice(0, 10)
          list = list.map(d => {
            return {
              label: d,
              value: d
            }
          })
          // 紀錄至全域
          this.autocompleteArr[this.focusedIndex] = list
          // 回傳結果
          cb(list)
          // 驗證
          this.$refs.form.validate()
        }
      })
    },
    // showAutocomplete (list) {
    //   const showList = list.reduce((prev, data) => {
    //     if (prev.find(d => d.value === data.value) == null) {
    //       prev.push(data)
    //     }
    //     return prev
    //   }, [])
    //   this.$set(this.autocompleteArr, this.focusedIndex, showList)
    // },
    addKeywordInput () {
      this.searchData.basicList.push({
        keyword: '',
        field: 'representative_name',
        'search-label': {
          user: this.getterUserEmail
        }
      })
    },
    removeKeywordInput (index) {
      this.searchData.basicList.splice(index, 1)
      this.autocompleteArr.splice(index, 1)
    },
    reset () {
      this.searchData = JSON.parse(JSON.stringify(this.defaultSearchData))
    },
    async search () {
      // -- 檢查是否關鍵字填寫完整 --
      // let checkEmpty = false
      // this.searchData.basicList.forEach(d => {
      //   if (!d.keyword) {
      //     checkEmpty = true
      //   }
      // })
      // if (checkEmpty) {
      //   this.$message.error('比較公司關鍵字未填寫完整')
      //   return
      // }
      let valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      // -- 比較項目 --
      // if (this.searchData.compareItems.length === 0) {
      //   this.$message.error('至少選擇一個比較項目')
      //   return
      // }
      // if (this.isShowVerdictsOptions === true) {
      //   if (!this.searchData.verdictsStartDate || !this.searchData.verdictsEndDate) {
      //     this.$message.error('未選擇法律訴訟日期範圍')
      //     return
      //   }
      //   // @Q@ 因日期欄位api未開出來，還不知規則所以待補
      // } else {
      //   // @Q@ 因日期欄位api未開出來，還不知規則所以待補
      // }
      // if (this.isShowNewsOptions === true) {
      //   if (!this.searchData.newsStartDate || !this.searchData.newsEndDate) {
      //     this.$message.error('未選擇新聞事件日期範圍')
      //     return
      //   }
      //   // @Q@ 因日期欄位api未開出來，還不知規則所以待補
      // } else {
      //   // @Q@ 因日期欄位api未開出來，還不知規則所以待補
      // }
      // -- 查詢條件 --
      let get = {}
      this.searchData.compareItems.forEach(d => {
        get[d] = {}
      })
      if (get.verdicts && this.verdictsStartDate && this.verdictsEndDate) {
        // if (this.verdictsStartDate) {
        //   this.searchData.compareItems.verdicts.start = this.verdictsStartDate
        // }
        // if (this.verdictsEndDate) {
        //   this.searchData.compareItems.verdicts.end = this.verdictsEndDate
        // }
        get.verdicts = {
          time: {
            start: this.verdictsStartDate,
            end: this.verdictsEndDate
          }
        }
      }
      if (get.news && this.newsStartDate && this.newsEndDate) {
        // if (this.newsStartDate) {
        //   this.searchData.compareItems.news.start = this.newsStartDate
        // }
        // if (this.newsEndDate) {
        //   this.searchData.compareItems.news.end = this.newsEndDate
        // }
        get.verdicts = {
          time: {
            start: this.newsStartDate,
            end: this.newsEndDate
          }
        }
      }
      this.searchData.basicList = this.searchData.basicList.map(d => {
        d['search-label'].label = d.keyword
        return d
      })
      // -- router（到查詢結果頁再依據uniID及相關參數來查詢） --
      // this.$router.push({
      routerService('push', {
        name: 'multiPersonDetailByPerson',
        params: {
          menuIndex: this.searchData.basicList[0].keyword // 預設先顯示第一個比較人物
        },
        query: {
          basicList: JSON.stringify(this.searchData.basicList),
          get: JSON.stringify(get)
        }
      })
    }
  },
  created () {
    this.searchData = JSON.parse(JSON.stringify(this.defaultSearchData))
  }
}
</script>
