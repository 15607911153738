<template>
  <div>
    <!-- 浮動按鈕 -->
    <div class="quickly-funcs">
      <!-- 下載按鈕 -->
      <el-button
        type="primary"
        class="quickly-btn"
        plain
        v-if="isShowDownloadBtn"
        @click="handleDownloadBtnClick"
        :disabled="isDownloading"
        >
        <span v-if="isDownloading">
          {{String(downloadingPercent)}}%
        </span>
        <font-awesome-icon
          v-else
          :icon="[ 'fas', 'download' ]"/>
      </el-button>
      <!-- 問題回報 -->
      <el-button
        type="primary"
        class="quickly-btn"
        plain
        @click="handleReportBtnClick">
        <font-awesome-icon :icon="[ 'fas', 'question' ]" />
      </el-button>
    </div>
    <!-- 問題回報lightbox -->
    <ReportLightbox
      :visible.sync="isReportLightboxVisible">
    </ReportLightbox>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import ReportLightbox from '@/components/ReportLightbox/ReportLightbox.vue'

export default {
  props: {
    // 下載按鈕callback function
    'downloadAction': {
      required: false,
      type: Function
    }
  },
  components: {
    ReportLightbox
  },
  data () {
    return {
      isReportLightboxVisible: false,
      isDownloading: false,
      downloadingPercent: 0
    }
  },
  computed: {
    isShowDownloadBtn () {
      if (this.downloadAction == null) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    handleReportBtnClick () {
      this.isReportLightboxVisible = true
    },
    async handleDownloadBtnClick () {
      // 開始下載
      this.isDownloading = true
      // 後端是否已回傳檔案
      let isProgressStart = false

      // -- 數字跑動 --
      this.showPercent(isProgressStart)

      // 執行下載任務
      const result = await this.downloadAction()

      this.finishPercent(result)
    },
    showPercent(isProgressStart){
      this.isDownloading = true
      this.downloadingPercent = 0
      // -- 數字跑動 --
      let t = 200 // 0.2s
      const downloadingNumberRun = () => {
        if (isProgressStart === true) {
          return
        }
        const r = Math.floor(Math.random() * 5) + 1 // 1~5
        this.downloadingPercent += r
        // 數字愈大就愈慢，超過80停下來
        if (this.downloadingPercent > 80) {
          if (this.downloadingPercent > 100) {
            this.downloadingPercent = 100
          }
          return
        } else if (this.downloadingPercent > 50) {
          t += 100
        }
        setTimeout(() => {
          downloadingNumberRun()
        }, t)
      }
      downloadingNumberRun()
    },
    finishPercent(result){
      if (result == true) {
        // 下載成功
        this.downloadingPercent = 100
        setTimeout(() => {
          this.isDownloading = false
          this.downloadingPercent = 0
        }, 3000)
      } else {
        // 下載失敗
        this.isDownloading = false
        this.downloadingPercent = 0
      }
    }
  },
}
</script>

